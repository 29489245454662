<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>出票管理</el-breadcrumb-item>
			<el-breadcrumb-item>出票申请</el-breadcrumb-item>
			<el-breadcrumb-item>草稿箱</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-button type="primary" @click="goOutApply">出票申请</el-button>
			<!--列表-->
			<el-table :data="tableList" border class="mt30">
				<el-table-column type="index" label="序号"></el-table-column>
				<el-table-column prop="billType" label="票据种类">
					<template v-slot="scope">
						<span v-if="scope.row.billType === 'AC01'">银票</span>
						<span v-else>商票</span>
					</template>
				</el-table-column>
				<el-table-column prop="acceptor" label="承兑人"></el-table-column>
				<el-table-column prop="payee" label="收票人"></el-table-column>
				<el-table-column prop="tradeAmt" label="票据包金额（元）"></el-table-column>
				<el-table-column prop="ticketDate" label="出票日期"></el-table-column>
				<el-table-column prop="dueDate" label="到期日"></el-table-column>
				<el-table-column label="操作" width="150px">
					<template v-slot="scope">
						<el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button size="mini" type="danger" @click="handleRemove(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页区域-->
			<!--分页区域 -->
			<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchForm.page"
							:page-sizes="[1, 2, 5, 10]"
							:page-size="searchForm.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
  export default {
    name: "BillOutDraft",
	  mounted(){
      // 获取列表数据
      this.getTableList()
	  },
	  data(){
      return {
        // 搜索表单
        searchForm: {
          page: 1,
          pageSize: 10
        },
        tableList: [], // 列表数据
	      total: 0, //总数
      }
	  },
	  methods: {
      // 获取列表数据
      async getTableList(){
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/draftSearch", this.$qs.stringify(this.searchForm))
        console.log(res)
	      if (res.code !== 200) return this.$message.error(res.msg)
	      this.total = res.list.total
	      this.tableList = res.list.records
      },
		  // 访问出票登记申请
      goOutApply(){
        this.$router.push({
	        name: 'drawBillApply'
        })
      },
		  // 编辑
      handleEdit(row){
        this.$router.push({
          name: 'drawBillApply',
	        query: {
            id: row.id
	        }
        })
      },
		  // 删除
      async handleRemove(row){
        let id = row.id
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/draftDelete",this.$qs.stringify({id}))
        if(res.code !== 200) return this.$message.error(res.msg)
	      this.getTableList()
      },
      // 监听pagesize改变的事件
      handleSizeChange(newSize){
        this.searchForm.pageSize = newSize;
        this.getTableList()
      },
      //监听 页码值 改变的事件
      handleCurrentChange(newPage){
        this.searchForm.page = newPage;
        this.getTableList()
      },
	  }
  }
</script>

<style scoped>

</style>